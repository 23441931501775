import { useCallback, useReducer } from 'react';
import {
  TIMER_RESET, TIMER_START, TIMER_STOP,
} from './constants';
import reducer from './reducer';

function useTimer() {
  const [state, dispatch] = useReducer(reducer, { startTime: 0, currentTime: 0 });
  const { startTime, currentTime } = state;

  const getTime = useCallback(() => startTime > 0 ? Date.now() - startTime : currentTime, [currentTime, startTime]);

  const start = useCallback(() => dispatch(TIMER_START), [dispatch]);
  const stop = useCallback(() => dispatch(TIMER_STOP), [dispatch]);
  const reset = useCallback(() => dispatch(TIMER_RESET), [dispatch]);

  return {
    getTime, start, stop, reset, dispatch,
  };
}

export default useTimer;
