/**
 * Exercise
 * Show exercise
 */

import React, { useEffect, useMemo } from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { Markdown } from 'react-showdown';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Controller from './Controller';
import focusElement from './focusElement';
import { Header, Nav } from './PageLayout';
import Images from './Images';
import useLockTimer from './useLockTimer';
import expandSteps from './expandSteps';
import useLanguage from './useLanguage';
import useResources from './useResources';
import TimeDisplay from './TimeDisplay';

function Exercise() {
  const params = useParams();
  const { hash } = useLocation();
  const { push } = useHistory();
  const resKey = params[0];
  const setLanguage = useLanguage()[1];
  const resources = useResources();
  const resource = resources[resKey];
  const {
    name, defaultStep, steps, text, tip, img, autoStart, alt,
  } = resource;
  const expanded = useMemo(() => expandSteps(steps, defaultStep), [steps, defaultStep]);
  const idx = Number(hash.slice(1) || 0);
  const step = idx > 0 && expanded[idx - 1];
  const { getTime, setPause } = useLockTimer(idx, step);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => focusElement('main'), 0);
  }, [hash]);

  function goHome() {
    push('#');
  }

  // const timerSpan = ;

  const languages = [
    { key: 'nl', label: 'Ook in het Nederlands' },
    { key: 'en', label: 'Also in English' },
  ];

  function renderBody() {
    if (idx === 0) {
      return (
        <>
          <h1>{name}</h1>
          <div className="overflow-auto">
            <Images src={img} alt={name} />
            <Markdown markup={text} />
          </div>
          <div className="d-flex justify-content-between align-items-end mt-1">
            <div>
              {languages.map(({ key, label }) => (
                alt === key && <Button key={key} variant="link" className="fs-7 p-0" onClick={() => setLanguage(key)}>{label}</Button>
              ))}
            </div>
            <Button size="lg" href="#1">Start</Button>
          </div>
        </>
      );
    }
    return step ? (
      <>
        <div>
          <TimeDisplay className="float-right" getTime={getTime} />
          <h1>{step.name}</h1>
        </div>
        {step.img && <Images src={step.img} alt="Showing this exercise" />}
        <div className="mb-3">
          <Controller step={step} next={`#${idx + 1}`} autoStart={autoStart} onPause={setPause} />
        </div>
        {step.text && (
          <div className="overflow-auto">
            <Markdown markup={step.text} />
          </div>
        )}
      </>
    ) : (
      <>
        <Alert style={{ clear: 'right' }} variant="success">
          <TimeDisplay className="float-right" getTime={getTime} />
          Finished!
        </Alert>
        <Markdown markup={tip} />
        <div className="text-right">
          <Button onClick={goHome}>Back Home</Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Header>
        <span>
          {resource.name}
          {' '}
          <a className="source" href={resource.source}>{resource.author}</a>
        </span>
        <Nav resKey={resKey} />
      </Header>
      <main className="d-flex flex-column overflow-hidden">
        {renderBody()}
      </main>
    </>
  );
}

export default Exercise;
