import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import useDatabase from './useDatabase';

const fields = [
  {
    key: 'name', label: 'Naam', type: 'text', attribs: { required: true },
  },
  {
    key: 'repetitions', label: 'Herhalingen', type: 'number', attribs: { min: 1 },
  },
  {
    key: 'duration', label: 'Tijdsduur', type: 'number', attribs: { min: 1 },
  },
  {
    key: 'rest', label: 'Rust', type: 'number', attribs: { min: 0 },
  },
];

function EditCustom(props) {
  const { onHide, id } = props;
  const { getByID, add, update } = useDatabase();
  const item = id > 0 ? getByID(id) : {
    name: '',
    repetitions: 3,
    duration: 30,
    rest: 10,
  };

  function handleSubmit(ev) {
    ev.preventDefault();
    const { elements } = ev.target;
    const result = {};
    fields.forEach(({ key, type }) => {
      const { value } = elements[key];
      result[key] = type === 'number' ? Number(value) : value;
    });
    if (id > 0) {
      update({ id, ...result });
    } else {
      add(result);
    }
    onHide(true);
  }
  return (
    <Modal show={Boolean(id)} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {item.id ? `'${item.name}' bewerken` : 'Workout toevoegen'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {fields.map(({
            key, label, type, attribs,
          }) => (
            <Form.Group key={key} controlId={key} className="mb-3">
              <Form.Label>{label}</Form.Label>
              <Form.Control defaultValue={item[key]} type={type} {...attribs} />
            </Form.Group>
          ))}
          <div className="d-flex justify-content-between">
            <Button type="submit" variant="primary">Submit</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

EditCustom.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.number,
  workoutByID: PropTypes.func,
  add: PropTypes.func,
  update: PropTypes.func,
};

export default EditCustom;
