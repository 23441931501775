/**
 * ProgressTimes
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ProgressBar } from 'react-bootstrap';
import { useHash } from 'react-use';
import useStageTimer from './useStageTimer';

const Wrapper = styled.div`
  position: relative;
  .progress {
    height: 38px;
  }
  .progress-bar {
    font-size: 24px;
  }
`;

const Counter = styled.div`
  font-size: 24px;
  text-shadow: 0 0 2px white;
  position: absolute;
  right: 0.25rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
`;

function ProgressTimer(props) {
  const {
    step, next, onPause,
  } = props;
  const setHash = useHash()[1];
  function handleNext() {
    setHash(next);
  }
  const {
    countLabel, progress, variant, remaining, start, stop,
  } = useStageTimer(step, handleNext);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    setPaused(false);
  }, [step]);

  function pause() {
    stop();
    onPause(true);
    setPaused(true);
  }

  function resume() {
    start();
    onPause(false);
    setPaused(false);
  }
  return (
    <Wrapper>
      <Counter>
        {countLabel}
      </Counter>
      <ProgressBar now={progress} variant={variant} label={remaining} />
      <ButtonDiv>
        {paused
          ? <Button variant="primary" onClick={resume}>Resume</Button>
          : <Button variant="light" onClick={pause}>Pause</Button>}
        <Button variant="light" onClick={handleNext}>{next ? 'Skip' : 'Stop'}</Button>
      </ButtonDiv>
    </Wrapper>
  );
}

ProgressTimer.propTypes = {
  step: PropTypes.object,
  onPause: PropTypes.func,
  next: PropTypes.string,
};

export default ProgressTimer;
