import { useMemo, useRef } from 'react';
import getStages from './getStages';

function useStages(step) {
  const stageIndex = useRef(0);
  const stages = useMemo(() => {
    stageIndex.current = 0;
    return getStages(step);
  }, [step]);
  const goNext = () => stageIndex.current++;

  const currentStage = stages[stageIndex.current];
  const nextStage = stages[stageIndex.current + 1];
  return { currentStage, nextStage, goNext };
}

export default useStages;
