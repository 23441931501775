function getCountLabel(stage, reps) {
  if (!stage) {
    return '';
  }
  if (stage.count === 0) {
    return 'Get Ready';
  }
  if (reps === 1) {
    return '';
  }
  return `${stage.count}/${reps}`;
}

export default getCountLabel;
