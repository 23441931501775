import { useEffect, useState } from 'react';

function useWakeLock(initial) {
  const [lock, setLock] = useState(null);
  const locked = lock && !lock.released;

  function request() {
    if (!locked && navigator.wakeLock) {
      navigator.wakeLock.request('screen').then(setLock);
    }
  }

  function release() {
    if (locked) {
      lock.release().then(() => setLock(null));
    }
  }

  const setWakeLock = (flag) => flag ? request() : release();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (initial) {
      request();
    }
    return release;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);
  return [locked, setWakeLock];
}

export default useWakeLock;
