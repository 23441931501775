function getStages(step) {
  const {
    duration, rest, repetitions = 1, includeLast, countDown = 5,
  } = step;
  const firstVariant = repetitions === 1 && !includeLast ? 'warning' : 'default';
  let offset = 0;
  const stages = countDown ? [
    {
      variant: firstVariant, duration: countDown, down: true, count: 0, offset,
    },
  ] : [];
  offset += countDown;
  if (!duration) {
    return stages;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= repetitions; i++) {
    stages.push({
      variant: 'success', duration, down: false, count: i, offset,
    });
    offset += duration;
    if (i < repetitions || includeLast) {
      stages.push({
        variant: 'warning', duration: rest, down: true, count: i, offset,
      });
    }
    offset += rest;
  }
  return stages;
}

export default getStages;
