/**
 * Nav
 */

import React, { useMemo } from 'react';
// import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useHash } from 'react-use';
import expandSteps from '../expandSteps';
import useResources from '../useResources';

export default function Nav(props) {
  const { resKey } = props;
  const resources = useResources();
  const resource = resources[resKey];
  const { push } = useHistory();
  const [hash] = useHash();
  const handleClick = (key) => () => push(`/${key}`);
  const index = Number(hash.slice(1));
  const steps = useMemo(() => resource && expandSteps(resource.steps).filter((step) => step.nav), [resource]);
  return (
    <nav>
      <Dropdown alignRight>
        <Dropdown.Toggle variant="link" size="sm" id="dropdown-menu">
          Menu
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#0" disabled={!index}>Start</Dropdown.Item>
          {steps && steps.map((step) => (
            <Dropdown.Item key={step.name} href={`#${step.id}`} disabled={index === step.id}>
              {step.name}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item key="home" onClick={handleClick('')}>Home</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </nav>
  );
}

Nav.propTypes = {
  resKey: PropTypes.string,
};
