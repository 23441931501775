/**
 * ProgressTimes
 */

import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import useTimer from '../useTimer';
import playSound from './playSound';
import getCountLabel from './getCountLabel';
import useStages from './useStages';

function useStageTimer(step, onNextStep) {
  const { currentStage, nextStage, goNext } = useStages(step);
  const { repetitions } = step;
  const [time, setTime] = useState(0);
  const {
    getTime, start, stop, reset,
  } = useTimer();

  useInterval(() => setTime(Math.floor(getTime() / 1000)), 200);

  useEffect(() => {
    reset();
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const remaining = currentStage.offset + currentStage.duration - time;

  useEffect(() => {
    playSound(remaining, currentStage, nextStage);
    if (remaining > 0) {
      return;
    }
    if (nextStage) {
      goNext();
    } else {
      setTime(0);
      onNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return {
    countLabel: getCountLabel(currentStage, repetitions),
    progress: 100 * ((currentStage.down ? remaining : currentStage.duration - remaining) / currentStage.duration),
    variant: currentStage.variant,
    remaining,
    start,
    stop,
  };
}

export default useStageTimer;
