/**
 * Page
 */

import styled from 'styled-components';

const Page = styled.div`
  height: calc(100vh);
  & > *:first-child {
    flex-grow: 1;
    overflow-y: hidden;
  }
`;

export default Page;
