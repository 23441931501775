import { createGlobalStyle } from 'styled-components';
import {
  green500, red500, blue500, orange500, lightBlack,
} from './colors';

const GlobalStyle = createGlobalStyle`
  main, footer {
    padding: 15px;
    color: ${lightBlack};
    ul {
      padding-inline-start: 30px
    }
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.2em;
  }
  .btn:not(.btn-link) {
    min-width: 6em;
  }
  .bg-success {
    background-color: ${green500}!important;
  }
  .bg-warning {
    background-color: ${orange500}!important;
  }
  .bg-danger {
    background-color: ${red500}!important;
  }
  .bg-primary {
    background-color: ${blue500}!important;
  }
  .text-success {
    color: ${green500}!important;
  }
  .text-warning {
    color: ${orange500}!important;
  }
  .text-danger {
    color: ${red500}!important;
  }
  .text-primary {
    color: ${blue500}!important;
  }  
  #dropdown-menu {
    margin-right: -15px;
  }
  .stretched-column {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
  .fs-7 {
    font-size: 0.9rem !important;
  }
  .fs-8 {
    font-size: 0.8rem !important;
  }
`;

export default GlobalStyle;
