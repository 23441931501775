import bilspieren from '../assets/decathlon/bilspieren.jpg';
import bolleRug from '../assets/decathlon/bolle-rug.jpg';
import hamstrings from '../assets/decathlon/hamstrings.jpg';
import holleRug from '../assets/decathlon/holle-rug.jpg';
import kindhouding from '../assets/decathlon/kindhouding.jpg';
import kuiten from '../assets/decathlon/kuiten.jpg';
import psoas from '../assets/decathlon/psoas.jpg';
import quadriceps from '../assets/decathlon/quadriceps.jpg';
import romp from '../assets/decathlon/romp.jpg';
import spreiden from '../assets/decathlon/spreiden.jpg';
import stretchingFemale from '../assets/decathlon/stretching-f.jpg';
import stretchingMale from '../assets/decathlon/stretching-m.jpg';

/* eslint-disable no-irregular-whitespace */
const decathlon = {
  author: 'Decathlon',
  name: 'Increase Your Flexibility',
  alt: 'nl',
  source: 'https://www.domyos.in/advice/increase-your-flexibility-how-get-it-right-a_330606',
  // img: 'https://www.domyos.in/sites/domyos/files/conseils-multisports-exercices-gagner-en-souplesse-le-bon-programme-header.jpg',
  img: [stretchingMale, stretchingFemale],
  text: `Feeling **stiff** or a little rusty? Have you considered taking up **stretching** to work out the kinks? When it comes to **increasing your flexibility**, there's no magic formula: you need to **stretch**! These nine exercises can help you boost your flexibility in no time.

## Stretching to Ease Stiff, Tight Muscles

Stretching helps you **ease tight muscles**, **release tension** **in the body** and **maintain your range of motion**. More and more people are flocking to this activity. And they're right! **Working on your flexibility** can prevent a lot of problems. It can all also:

- Improve your muscle strength by increasing your range of motion.
- Speed recovery
- Reduce injury risk
- Ease joint and muscle tension
- Increase flexibility and reduce stiffness
- Flexibility refers to the range of muscular and joint motion you have.`,
  tip: 'A tip from the coach: To **maintain your flexibility**, do these **stretches** every day. For even better results, hit the gym for a weekly **stretching class**.',
  autoStart: false,
  defaultStep: {
    countDown: 5,
  },
  steps: [
    {
      name: 'Warm Up 1',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-1.jpg',
      text: 'Inhale and raise your arms above your head. Exhale and release your arms. Repeat a total of 7 times.',
    },
    {
      name: 'Warm Up 2',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-2.jpg',
      text: 'Inhale and raise your arms above your head as you rise up on to your toes, exhale, then relax and lean your upper body forwards. Repeat a total of 5 times.',
    },
    {
      name: 'Warm Up 3',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-3.jpg',
      text: 'Inhale and raise your arms above your head, rise up on to your toes and completely relax and fold forwards. Slowly roll back up, one vertebra at a time.',
    },
    {
      name: 'Warm Up 4',
      text: `Before starting the stretches, do a few **joint warm-ups**: this will help you get things moving and slightly increase your body temperature.
- Roll your ankles
- Roll your shoulders
- Roll your wrists
- Lift your shoulders
- Rotate your pelvis
- Roll your neck
-  Lean your upper body to the right and left`,
    },
    {
      name: '1. Cat / Cow',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-1.jpg',
      img: [bolleRug, holleRug],
      text: `- *Targeted muscles:* Back muscles
- *Performing the exercise:* Come onto your hands and knees: place your hands below your shoulders (shoulder-width apart) and your knees under your hips (hip-width apart). Start with your back straight.
- *Breathing:* inhale as you round your back. Exhale as you drop your stomach towards the floor. Feel the even movement through each vertebra.
- *Repetitions:* Alternate between cat/cow 5 times.`,
      repetitions: 5,
      duration: 8,
      rest: 8,
      includeLast: true,
    },
    {
      name: '2. Cobra (On you Stomach, On the Floor)',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-2.jpg',
      img: romp,
      text: `- *Targeted muscles:* Stretches the abs and releases the back
- *Performing the exercise:* Lie on your stomach, hands at either side of your chest, and push up to raise your chest. Lower your shoulders away from your ears and look forward. Don't clench the glutes. If you feel a pinch in the lower back, push your thighs a little further away from you.
- *Breathing:* Exhale as you come up.
- *Repetitions:* 5 times, holding the position for 30 seconds.`,
      repetitions: 5,
      duration: 30,
      rest: 15,
    },
    {
      name: '3. Child\'s Pose',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-3.jpg',
      img: kindhouding,
      text: `- *Targeted muscles:* Lengthens and relaxes the back
- *Performing the exercise:* Sit back on your heels and stretch your arms our as far as you can in front of you. Keep your hands flat on the floor with your fingers pointing forward.
- *Breathing:* Inhale and exhale as you try to stretch your hands forward to maximise the stretch along your spine.
- *Repetitions:* Hold this position for 3 x 30 seconds.`,
      repetitions: 3,
      duration: 30,
      rest: 15,
    },
    {
      name: '4. Glute Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-1.jpg',
      img: bilspieren,
      text: `- *Targeted muscles:* Glutes (gluteus maximus, gluteus medius and gluteus minimus)
- *Performing the exercise:* Lie down on your back and place your ankle on the opposite knee, then hold your thigh or tibia (depending on how flexible you are). Remember to relax your upper body throughout the stretch.
- *Breathing:* Inhale, then exhale as you bring your knee to your chest.
- *Repetitions:* Repeat for 3 x 30 seconds on each side.`,
      repetitions: 6,
      duration: 30,
      rest: 10,
    },
    {
      name: '5. Adductor Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-2.jpg',
      img: spreiden,
      text: `- *Targeted muscles:* Adductors
- *Performing the exercise:* Lie on your mat, and widen your legs as far as possible, staying in contact with the wall. You should feel a stretch along the inner thighs.
- *Breathing:* Inhale, then exhales as you release your legs down.
- *Repetitions:* Hold this position for 3 x 30 seconds.`,
      repetitions: 3,
      duration: 30,
      rest: 15,
    },
    {
      name: '6. Standing Quad Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-3.jpg',
      img: quadriceps,
      text: `- *Targeted muscles:* The four muscles of the quadriceps (rectus femoris, vastus lateralis, vastus intermedius and vastus medialis).
- *Performing the exercise:* From a standing position, grab the top of your foot and bring your heel as close as you can to your buttocks. Be sure to keep your hips in a neutral, slightly forward position to get the most stretch at the thigh. If you have trouble balancing, you can also do this stretch while lying on your stomach.
- *Breathing:* Breath slowly throughout the exercise.
- *Repetitions:* Do this stretch 3 x 30 seconds on each side, trying to increase the stretch with each rep.`,
      repetitions: 6,
      duration: 30,
      rest: 10,
    },
    {
      name: '7. Psoas Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-4.jpg',
      img: psoas,
      text: `- *Targeted muscles:* Iliopsoas at the hip
- *Performing the exercise:* Start in a lunge position, with your back knee on the ground and your front knee directly above your ankle.
- *Breathing:* Inhale, then exhale as you push your hips down and forward. Once you're in position, breathe slowly and deeply to feel the stretch in at the hip attachment point.
- *Repetitions:* Hold for 30 to 45 seconds and do twice on each side.`,
      repetitions: 4,
      duration: 30,
      rest: 15,
    },
    {
      name: '8. Hamstring Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-5.jpg',
      img: hamstrings,
      text: `- *Targeted muscles:* Hamstrings (semitendinosus, semimembranosus and biceps femoris)
- *Performing the exercise:* Stand with one leg straight, foot flexed, and the other slightly bent.
- *Breathing:* Inhale, then exhale as you lean your upper body forward from the hips. You should feel the stretch along the back of your straight leg.
- *Repetitions:* Hold for 30 to 45 seconds and do twice on each side.`,
      repetitions: 4,
      duration: 30,
      rest: 10,
    },
    {
      name: '9. Calf Stretch',
      // img: 'https://www.domyos.in/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-6.jpg',
      img: kuiten,
      text: `- *Targeted muscles:* Calf muscles (gastrocnemius and soleu, composing the triceps surae).
- *Performing the exercise:* Start in a standing lunge position (one leg bent to 90°, the other straight).
- *Breathing:* Inhale, then exhale as you stretch your back heel down towards the ground.
- *Repetitions:* Hold for 30 to 45 seconds and do twice on each side.`,
      repetitions: 4,
      duration: 30,
      rest: 10,
    },
  ],
};

export default decathlon;
