import logo from '../assets/rugoefeningen/trainerz-magazine-logo-header.png';
import rugBrug from '../assets/rugoefeningen/rug-brug.jpg';
import rugHeffen from '../assets/rugoefeningen/rug-arm-en-benen-heffen.jpg';
import rugSuperman from '../assets/rugoefeningen/rug-superman.jpg';
import rugExtension from '../assets/rugoefeningen/rug-extension.jpg';
import rugRoeien from '../assets/rugoefeningen/rug-roeien.jpg';
import rugPomp from '../assets/rugoefeningen/rug-roeien-pomphouding.jpg';
import rugPlank from '../assets/rugoefeningen/rug-plank.jpg';
import rugSquats from '../assets/rugoefeningen/rug-squats.jpg';
import rugInverted from '../assets/rugoefeningen/rug-inverted-row.jpg';

/* eslint-disable no-irregular-whitespace */
const rugoefeningen = {
  author: 'TrainerZ',
  name: 'Rugoefeningen voor thuis',
  img: logo,
  source: 'https://trainerz.be/9-rugoefeningen-voor-thuis/',
  text: `Of je nu last hebt van je onderrug of niet, man of vrouw bent, atletisch of iets minder gespierd bent … (Onder)rugoefeningen zijn sterk aan te raden voor iedereen. Ze bevorderen de beweeglijkheid en doorbloeding in je spieren, verstevigen je houding, versterken je kracht én je krijgt er bovendien ook nog eens mooie stevige vormen van.

  Je zou bijna gek moeten zijn om de spieren in je rug niet te onderhouden. Daarom deze rugoefeningen waarmee je thuis aan de slag kan. Zo hoef je je geen zorgen meer te maken om die dure fitnessabonnementen.`,
  autoStart: false,
  steps: [
    {
      name: 'Brugoefening',
      img: rugBrug,
      text: `Ga op je rug liggen, plooi je benen, en plaats je voeten plat op de grond. Hef je billen omhoog totdat je bovenbenen en bovenlichaam één rechte lijn vormen. Hou deze positie zo’n 10 seconden aan en span je bil- en rugspieren stevig op. Hoe getrainder je bent, hoe langer je dit kan aanhouden. Herhaal de oefening ongeveer 15 keer.

Gevorderden kunnen deze oefening ook doen terwijl ze één been uitstrekken bij het heffen van de billen.`,
    },
    {
      name: 'Tegenovergestelde arm en been uitstrekken',
      img: rugHeffen,
      text: 'Start op handen en knieën, met je handen onder je schouders en je knieën onder je heupen. Houd je rug en nek recht. Buig je hoofd dus niet naar boven om vooruit te kijken, maar richt je ogen op de grond. Span je billen en buik stevig op, doe één arm omhoog zodat deze in rechte lijn met je rug is. Tegelijkertijd hef je ook je tegenovergestelde been. Houd deze positie even aan en herhaal de beweging met je andere arm en been.',
    },
    {
      name: 'Superman',
      img: rugSuperman,
      text: 'Ga op je buik liggen en strek je armen en benen. Hef één arm/schouder van de grond, samen met je tegenovergestelde been. Hou dit even aan en wissel dan van kant. Gevorderden kunnen deze oefening ook doen terwijl armen, schouders en benen tegelijkertijd van de grond gehoffen worden.',
    },
    {
      name: 'Rug extensie met bal',
      img: rugExtension,
      text: 'Ga met je buik en heupen op de fitnessbal liggen. Strek je benen, houd je handen in je nek en laat je bovenlichaam langzaam zakken. Hef je bovenlichaam tot wanneer je boven- en onderlichaam 1 rechte lijn vormen, zeker niet verder. Hou deze positie even aan en herhaal de oefening zo’n 15 keer.',
    },
    {
      name: 'Voorovergebogen roeibeweging',
      img: rugRoeien,
      text: 'Plaats je voeten op heupbreedte, buig licht door je knieën, en kantel je bovenlichaam naar voor. Houd gedurende de hele oefening je rug mooi recht. Starten doe je met hangende armen. Vervolgens trek je je armen naar omhoog en laat je je ellebogen langs je lichaam glijden. Hou die pose even aan en keer terug naar je startpositie.',
    },
    {
      name: 'Roeibeweging uit pomphouding',
      img: rugPomp,
      text: 'Start in pomphouding, met gestrekt armen en een rechte (!) rug. Plaats je voeten wat uit mekaar om je evenwicht te. bewaren tijdens de oefening. Trekt je armen afwisselend naar langzaam naar omhoog en hou die positie even aan. Let er op dat je niet scheef gaat hangen!',
    },
    {
      name: 'De plank',
      img: rugPlank,
      text: 'Je ellebogen, voorarmen en tenen plaats je op een fitnessmatje. De rest van je lichaam strek je uit. Tracht je lichaam zo recht te houden als een rechte plank en hou die positie even aan. Start met 10 seconden maar bouw geleidelijk aan naar 30 seconden. Herhaal de oefening zo’n 4 keer.',
      countDown: 10,
      duration: 20,
      repetitions: 4,
      rest: 10,
    },
    {
      name: 'Rug squats',
      img: rugSquats,
      text: 'Net zoals bij gewone squats plaats je je voeten op schouderbreedte. Buig vervolgens door je knieën, kantel je bovenlichaam naar voor (houd hierbij je rug recht!) en strek je armen voor je uit. Hou even aan en ga vervolgens opnieuw rechtop staan door je billen stevig op te spannen. Doe dat zo’n 10 keer.',
    },
    {
      name: 'Hangende Roeibewegingen (Bodyweight Rows)',
      img: rugInverted,
      text: `Last but not least: een rugoefening voor gevorderden. Met de hangende roeibeweging train je de kracht in je armen en bovenrug, en verstevig je de rest van de rug. Ga onder een tafel hangen door de zijkant stevig vast te grijpen. Strek je lichaam uit in plankvorm! Probeer nu, met een gestrekt lichaam, 10 keer je borst tot aan de onderkant van de tafel te trekken. Et voila!

Tot slot: vergeet niet dat rugoefening altijd gepaard gaan met buikspieroefeningen. Alleen door beiden te verstevigen krijg je echt een stevig spierkorset.`,
    },
  ],
};

export default rugoefeningen;
