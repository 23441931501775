import bilspieren from '../assets/decathlon/bilspieren.jpg';
import bolleRug from '../assets/decathlon/bolle-rug.jpg';
import hamstrings from '../assets/decathlon/hamstrings.jpg';
import holleRug from '../assets/decathlon/holle-rug.jpg';
import kindhouding from '../assets/decathlon/kindhouding.jpg';
import kuiten from '../assets/decathlon/kuiten.jpg';
import psoas from '../assets/decathlon/psoas.jpg';
import quadriceps from '../assets/decathlon/quadriceps.jpg';
import romp from '../assets/decathlon/romp.jpg';
import spreiden from '../assets/decathlon/spreiden.jpg';
import stretchingFemale from '../assets/decathlon/stretching-f.jpg';
import stretchingMale from '../assets/decathlon/stretching-m.jpg';

/* eslint-disable no-irregular-whitespace */
const decathlon = {
  author: 'Decathlon',
  name: 'Soepeler worden',
  alt: 'en',
  source: 'https://www.domyos.nl/tips/soepeler-worden-het-juiste-programma-a_330610',
  // img: 'https://www.domyos.nl/sites/domyos/files/conseils-multisports-exercices-gagner-en-souplesse-le-bon-programme-header.jpg',
  img: [stretchingMale, stretchingFemale],
  text: `Voel je je **stijf** of wat stram? Heb je al eens **stretching** overwogen om daar iets aan te doen? **Soepeler worden** en dat ook blijven, moeilijk is het niet: je moet gewoon **stretchen**! Dat is meteen ook het hoofddoel van deze discipline. Tijd dus om ze te testen en te ontdekken aan de hand van een programma met 9 oefeningen.

## Versoepelen dankzij stretching

Bij stretching ga je **je spieren rekken**, de **spanning in je lichaam** verdrijven en de **mobiliteit van je gewrichten onderhouden**. Steeds meer mensen zien het belang in van een goede stretching. En terecht! **Aan je soepelheid werken**, dat is tal van kleine kwaaltjes voorkomen. Maar er is meer! Het is ook:

- Je spieren versterken door je bewegingsbereik te vergroten.
- Beter recupereren.
- Het risico op blessures verkleinen.
- Gewricht- en spierspanningen verlichten.
- Soepeler en flexibeler worden.
- Met soepelheid bedoelen we het vermogen om lichaamsbewegingen uit te voeren met een zo groot mogelijk gewrichts- en spierbereik.`,
  tip: 'Tip van de coach: om **soepel te blijven**, doe je deze **rekoefeningen** best elke dag. En nog beter is dat je ze aanvult met een wekelijkse **stretchingles**.',
  autoStart: false,
  defaultStep: {
    countDown: 5,
  },
  steps: [
    {
      name: 'Warming up 1',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-1.jpg',
      text: 'Adem in en breng je armen boven het hoofd. Adem vervolgens uit en laat je armen zakken. Herhaal deze beweging in totaal 7 keer.',
    },
    {
      name: 'Warming up 2',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-2.jpg',
      text: 'Adem in en breng de armen boven het hoofd, ga op je tenen staan, adem uit en kantel je bovenlichaam naar voren. Herhaal deze beweging in totaal 5 keer.',
    },
    {
      name: 'Warming up 3',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-echauffement-stretching-souplesse-3.jpg',
      text: 'Adem in en breng de armen boven het hoofd, ga op je tenen staan, adem uit en laat je bovenlichaam volledig zakken tot beneden. Ontrol je rug langzaam, wervel voor wervel.',
    },
    {
      name: 'Warming up 4',
      text: `Alvorens te beginnen stretchen, doe je een paar **oefeningen om je gewrichten mobieler te maken**: op die manier activeer je je lichaam en verhoog je lichtjes je lichaamstemperatuur.
- Mobilisatie van het enkelgewricht door rotaties.
- Mobilisatie van het schoudergewricht door rotaties.
- Mobilisatie van de polsen.
- Optillen van de schouders.
- Mobilisatie en rotatie van het bekken.
- Mobilisatie van de nek.
- Kantelen van de romp naar rechts en links.`,
    },
    {
      name: '1. Ronde rug / holle rug',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-1.jpg',
      img: [bolleRug, holleRug],
      text: `- *Getrainde spieren:* de rugspieren.
- *Uitvoering van de oefening:* zet je op handen en knieën: plaats je handen onder de schouders (op schouderbreedte) en de knieën onder de heupen (op bekkenbreedte). Controleer of je rug mooi plat is.
- *Ademhaling:* adem in en maak je rug bol. Adem uit en maak je rug hol. Je zal voelen hoe je ruggenwervels mobiliseren.
- *Herhalingen:* 5 keer afwisselen tussen bolle en holle rug.`,
      repetitions: 5,
      duration: 8,
      rest: 8,
      includeLast: true,
    },
    {
      name: '2. Romp uitrekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-2.jpg',
      img: romp,
      text: `- *Getrainde spieren:* stretchen van de buikspieren en versoepelen van de rug.
- *Uitvoering van de oefening:* ga languit liggen op de buik, leg je handen langs weerszijden van de borst en duw je op. Laat de schouders zakken en kijk goed voor je. Span de billen niet op. Als je een knelling voelt in de onderrug, til de dijen dan wat op.
- *Ademhaling:* blaas uit terwijl je omhoog komt.
- *Herhalingen:* 5 keer, houd daarbij de houding telkens 30 seconden aan.`,
      repetitions: 5,
      duration: 30,
      rest: 15,
    },
    {
      name: '3. Kindhouding',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-haut-du-corps-3.jpg',
      img: kindhouding,
      text: `- *Getrainde spieren:* de rug verlengen en ontspannen.
- *Uitvoering van de oefening:* laat je achterwerk op je hielen rusten en probeer de armen zo ver mogelijk voor je uit te strekken. Houd de handen goed plat op de grond, strek je vingers naar voren.
- *Ademhaling:* adem in en vervolgens weer uit en probeer je armen zo veel mogelijk te ontspannen, om je ruggengraat zo ver mogelijk uit te rekken.
- *Herhalingen:* houd deze positie 3 keer 30 seconden aan.`,
      repetitions: 3,
      duration: 30,
      rest: 15,
    },
    {
      name: '4. Bilspieren rekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-1.jpg',
      img: bilspieren,
      text: `- *Getrainde spieren:* bilspieren (kleine, middelgrote en grote bilspieren).
- *Uitvoering van de oefening:* ga languit liggen, plaats de enkel op de tegenovergestelde knie en neem de achterkant van je dij of scheen vast (afhankelijk van hoe flexibel je al bent). Denk eraan om je bovenlichaam te ontspannen tijdens de hele oefening.
- *Ademhaling:* adem in en weer uit en breng daarbij je knie naar je borst.
- *Herhalingen:* 3 keer 30 seconden per kant.`,
      repetitions: 6,
      duration: 30,
      rest: 10,
    },
    {
      name: '5. Adductoren spreiden',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-2.jpg',
      img: spreiden,
      text: `- *Getrainde spieren:* adductoren.
- *Uitvoering van de oefening:* ga languit op je matje liggen, spreid je benen zo ver mogelijk en houd op elk moment contact met de muur. Je moet een rekking voelen aan de binnenkant van de dijen.
- *Ademhaling:* adem in en weer uit en ontspan je benen.
- *Herhalingen:* houd deze houding 3 keer 30 seconden aan.`,
      repetitions: 3,
      duration: 30,
      rest: 15,
    },
    {
      name: '6. Quadriceps staand rekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-3.jpg',
      img: quadriceps,
      text: `- *Getrainde spieren:* de 4 koppen van de quadriceps (de binnenste, de buitenste, de middelste en de rechte dijbeenspier).
- *Uitvoering van de oefening:* zet je rechtop, neem je voet vast bij de wreef en trek je hiel tegen je billen. Let erop dat je bekken neutraal blijft en licht naar voren gekanteld, zodat je de rekking maximaal voelt in de voorkant van de dij. Vind je je evenwicht niet zo goed, dan kan je deze oefening ook liggend op de buik uitvoeren.
- *Ademhaling:* adem traag en gecontroleerd in en uit tijdens de oefening.
- *Herhalingen:* 3 x 30 seconden stretchen langs elke kant en proberen het bereik bij elke beurt te verhogen.`,
      repetitions: 6,
      duration: 30,
      rest: 10,
    },
    {
      name: '7. Psoas rekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-4.jpg',
      img: psoas,
      text: `- *Getrainde spieren:* de iliopsoas, ter hoogte van de heup.
- *Uitvoering van de oefening:* ga in uitvalspas staan, met de achterste knie op de grond en de voorste knie in het verlengde van de enkel.
- *Ademhaling:* adem in en vervolgens uit terwijl je je bekken omlaag en naar voren kantelt. Zodra je die positie hebt bereikt, adem je rustig en gecontroleerd om de binnenste heupspieren goed te voelen rekken.
- *Herhalingen:* doe 2 herhalingen (30 tot 45 seconden) voor elk been.`,
      repetitions: 4,
      duration: 30,
      rest: 15,
    },
    {
      name: '8. Hamstrings rekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-5.jpg',
      img: hamstrings,
      text: `- *Getrainde spieren:* hamstrings (halfvliezige spier, halfpezige spier en de tweehoofdige dijspier).
- *Uitvoering van de oefening:* ga rechtop staan, strek één been met de voet geplooid en buig het andere lichtjes.
- *Ademhaling:* adem in en vervolgens weer uit en kantel daarbij je romp naar voren vanaf je heup. Je moet het achteraan je been voelen rekken.
- *Herhalingen:* doe 2 herhalingen (30 tot 45 seconden) voor elk been.`,
      repetitions: 4,
      duration: 30,
      rest: 10,
    },
    {
      name: '9. Kuiten rekken',
      // img: 'https://www.domyos.nl/sites/domyos/files/gagner-en-souplesse-le-bon-programme-stretching-exercice-bas-du-corps-6.jpg',
      img: kuiten,
      text: `- *Getrainde spieren:* de kuitspieren (laterale en mediale gastrocnemius + de diepe kuitspier = triceps surae).
- *Uitvoering van de oefening:* ga in uitvalspas staan (één been geplooid, het andere gespannen).
- *Ademhaling:* adem in en vervolgens weer uit en duw daarbij de achterste hiel naar beneden tot je een rekking voelt aan de achterkant van het been.
- *Herhalingen:* doe 2 herhalingen (30 tot 45 seconden) voor elk been.`,
      repetitions: 4,
      duration: 30,
      rest: 10,
    },
  ],
};

export default decathlon;
