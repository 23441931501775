import burpee from '../assets/hiit/burpee.jpg';
import compass from '../assets/hiit/compass.jpg';
import dumbbellSwing from '../assets/hiit/dumbbell-swing.jpg';
import dumbbellRow from '../assets/hiit/dumbbell-row.jpg';
import gateSwing from '../assets/hiit/gate-swing.jpg';
import hipSwing from '../assets/hiit/hip-swing.jpg';
import hiitKrachtTrainingHardlopen from '../assets/hiit/hiit-kracht-training-hardlopen.jpg';
import inchworms from '../assets/hiit/inchworms.jpg';
import jumpingJack from '../assets/hiit/jumping-jack.jpg';
import lungeReach from '../assets/hiit/lunge-reach.jpg';
import pogoHop from '../assets/hiit/pogo-hop.jpg';
import reverseLunge from '../assets/hiit/reverse-lunge.jpg';
import runner from '../assets/hiit/runner.jpg';
import singleLegPlank from '../assets/hiit/single-leg-plank.jpg';
import singleLegPushup from '../assets/hiit/single-leg-pushup.jpg';
import threePoint from '../assets/hiit/three-point.jpg';

/* eslint-disable no-irregular-whitespace */
const hiit = {
  author: 'Runner\'s World',
  name: 'HIIT circuit',
  source: 'https://www.runnersworld.com/nl/training/a30452005/hiit-krachttraining-hardlopers/',
  img: hiitKrachtTrainingHardlopen,
  text: `## Intervaltraining combineren met krachttraining

[Download/view PDF](/download/runnersworld_HIIT_circuit.pdf)

De gehele training bestaat uit twee hoofdonderdelen: een dynamische warming-up (met 6 oefeningen) en de hoofdtraining (met 9 oefeningen). De oefeningen van de warming-up doe je ieder 30 seconden. Dan rust je even uit en ga je door naar de reeks hoofdoefeningen. De training gaat dus als volgt:

**3 minuten:**  
Dynamische opwarming met een de oefeningen A t/m F

**1 minuut:**  
Rust

**6 minuten:**  
Oefeningen 1, 2 en 3. Elk gedurende 1 minuut. Twee setjes.

**1 minuut:**  
Rust

**9 minuten:**  
Oefeningen 4, 5 en 6. Elk gedurende 1 minuut. Drie setjes.

**1 minuut:**  
Rust

**9 minuten:**  
Oefeningen 7, 8 en 9. Elk gedurende 1 minuut. Drie setjes.`,
  tip: 'Voer deze één of twee keer per week uit op de dagen dat je niet hardloopt.',
  autoStart: true,
  defaultStep: {
    countDown: 2,
    repetitions: 1,
  },
  steps: [
    {
      name: 'Dynamische warming-up',
      text: `Elke oefening begint vanuit staande positie, met je voeten naast elkaar.
- A. Jumping Jack
- B. Pogo Hop
- C. Gate Swing
- D. Hip Swing
- E. Lunge met achterover reiken
- F. Inchworms`,
      countDown: 5,
      repeat: 1,
      defaultStep: {
        duration: 30,
      },
      steps: [
        {
          name: 'A. Jumping Jack',
          img: jumpingJack,
          text: 'Spring omhoog, spreid de benen, land en spring meteen weer omhoog terwijl je de benen sluit. Je landt op de bal van je voet. Doe deze oefening 30 seconden.',
        },
        {
          name: 'B. Pogo Hop',
          img: pogoHop,
          text: 'Hup op en neer, met ondersteuning van de armen, kom niet hoger dan 10 cm van de grond. De afzet volgt meteen op de landing, op de bal van de voet. Doe deze oefening 30 seconden.',
        },
        {
          name: 'C. Gate Swing',
          img: gateSwing,
          text: 'Spring omhoog en land met gespreide benen. De voeten wijzen naar buiten. Houd je rug recht, duw je heupen naar achteren en zak in een squatpositie. Beweeg de knieën daarbij naar buiten. Spring terug naar de beginstand. Doe deze oefening 30 seconden.',
        },
        {
          name: 'D. Hip Swing',
          img: hipSwing,
          text: 'Span romp- en bilspieren aan terwijl je je rechterbeen zo ver mogelijk naar voren en naar achteren zwaait. Wissel na 15 seconden van been. Doe deze oefening 30 seconden.',
        },
        {
          name: 'E. Lunge met achterover reiken',
          img: lungeReach,
          text: 'Stap met je linkerbeen naar achteren tot de lunge-positie. Strek je armen omhoog en hel met de romp licht achterwaarts. Laat je armen zakken en duw jezelf met je rechtervoet terug tot stand. Wissel van been. Doe deze oefening 30 seconden.',
        },
        {
          name: 'F. Inchworms',
          img: inchworms,
          text: 'Buig voorover tot de handen de vloer raken. Houd je benen gestrekt en loop met je handen naar voren tot de romp gestrekt is. Maak dan kleine stapjes met je voeten in de richting van je handen, waarbij de heupen weer omhoog komen. Beweeg op die manier voorwaarts gedurende 30 seconden. Rust een minuut en begin dan met de hoofdoefeningen.',
        },
      ],
    },
    {
      name: 'Hoofdoefeningen 1',
      text: `3 oefeningen, elk gedurende 1 minuut. Twee setjes.
- 1 / Three-point balance touch
- 2 / Single-leg push-up
- 3 / Reverse lunge met toe touch`,
      countDown: 60,
      repeat: 2,
      defaultStep: {
        duration: 60,
        halfway: true,
      },
      steps: [
        {
          name: '1 / Three-point balance touch',
          img: threePoint,
          text: 'Sta op je linkerbeen en buig je knie tot een kwart squat. Reik vervolgens met je rechtervoet zo ver mogelijk naar voren, tik de vloer aan en keer terug naar de beginpositie. Doe hetzelfde reikend naar rechts, en naar achteren. Wissel dan van been. Doe deze oefening gedurende een minuut en ga dan verder met oefening 2.',
        },
        {
          name: '2 / Single-leg push-up',
          img: singleLegPushup,
          text: 'Neem een push-up positie aan. Buig de armen en til tegelijkertijd je rechterbeen van de vloer. Breng je been terug naar de grond en druk jezelf op naar de push-up positie. Herhaal gedurende een minuut en ga dan verder met oefening 3.',
        },
        {
          name: '3 / Reverse lunge met toe touch',
          img: reverseLunge,
          text: 'Stap met je rechterbeen naar achteren en laat deze zakken totdat je onderbeen horizontaal is. Zet af met je rechtervoet en kom terug tot stand op je linkerbeen, zwaai je rechterbeen door en op het moment dat die gestrekt naar voren is reik je met je linkerhand naar je tenen. Wissel van been. Doe deze oefening gedurende een minuut en ga dan terug naar oefening 1. Neem na het voltooien van de tweede cyclus een minuut rust en ga dan verder met oefening 4.',
        },
      ],
    },
    {
      name: 'Hoofdoefeningen 2',
      text: `3 oefeningen, elk gedurende 1 minuut. Drie setjes.
- 4 / Burpee met push-up
- 5 / Dumbbell row
- 6 / Runner`,
      countDown: 60,
      repeat: 3,
      defaultStep: {
        duration: 60,
        halfway: true,
      },
      steps: [
        {
          name: '4 / Burpee met push-up',
          img: burpee,
          text: 'Hurk diep en plaats je handen op de vloer. Spring met de voeten naar acteren tot een push-up positie, doe één push-up. Spring terug naar de squat positie en spring omhoog, met je handen boven je hoofd. Land en herhaal. Doe deze oefening gedurende een minuut en ga dan verder met oefening 5.',
        },
        {
          name: '5 / Dumbbell row',
          img: dumbbellRow,
          text: 'Houd twee handhalters vast met gestrekte armen. Duw je heupen naar achteren, buig je knieën en laat je romp met de rug goed recht naar voren zakken. Trek de halters omhoog naar de zijkanten van je borst, pauzeer en laat langzaam zakken. Herhaal zonder de positie van de romp te veranderen. Doe deze oefening gedurende een minuut en ga dan verder met oefening 6.',
        },
        {
          name: '6 / Runner',
          img: runner,
          text: 'Ga op je rug liggen, ellebogen langs je lichaam en je armen gebogen. Til je schouders en bovenrug van de vloer. Breng de rechterknie naar je borst brengt en je linkerarm naar voren, en zak terug naar de beginpositie. Doe hetzelfde met je andere been. Wissel gedurende een minuut en ga dan terug naar oefening 4. Na het voltooien van de derde cyclus neem je een minuut rust, om verder te gaan met het laatste setje van drie, te beginnen met oefening 7.',
        },
      ],
    },
    {
      name: 'Hoofdoefeningen 3',
      text: `3 oefeningen, elk gedurende 1 minuut. Drie setjes.
- 7 / Dumbbell swing
- 8 / Compass lunge
- 9 / Dynamic plank`,
      countDown: 60,
      repeat: 3,
      defaultStep: {
        duration: 60,
        halfway: true,
      },
      steps: [
        {
          name: '7 / Dumbbell swing',
          img: dumbbellSwing,
          text: 'Houd het ene uiteinde van een dumbbell met beide handen vast. Buig je knieën en beweeg het gewicht terug tussen je benen naar achter. Breng dan de heupen krachtig naar voren en zwaai het gewicht met gestrekte armen omhoog. Zwaai terug naar de beginpositie. Herhaal dit gedurende een minuut en ga dan verder met oefening 8.',
        },
        {
          name: '8 / Compass lunge',
          img: compass,
          text: 'Lunge naar voren (noordwaarts) met je rechterbeen. Zet je af met je voorste voet om terug te komen tot de beginstand. Herhaal dit in elke windrichting - noordoost, oost, zuidoost en zuid. Wissel dan van been en stap uit in de richting zuidwest, west, noordwest en noord. Doe deze oefening gedurende een minuut en ga dan verder met oefening 9.',
        },
        {
          name: '9 / Dynamic plank',
          img: singleLegPlank,
          text: 'Til vanuit de plankpositie je linkerbeen op en strek je rechterarm voor je uit. Houd vijf seconden vast. Keer dan terug naar de plank positie en hef je rechterbeen en linkerarm gedurende vijf seconden. Wissel zo gedurende een minuut van kant en ga verder met twee setjes van de oefeningen 7, 8 en 9. Na de derde cyclus zit je HIIT-krachttraining er op.',
        },
      ],
    },
  ],
};

export default hiit;
