/**
 *  Footer
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightBlack, green500 } from '../../styles/colors';

const Wrapper = styled.footer`
  border-top: 1px solid ${green500};
  color: ${lightBlack};
  text-align: center;
  font-size: small;
  a {
    font-style: italic;
    white-space: nowrap;
  }
  img {
    height: 12px;
    margin-left: 5px;
    transform: translateY(-2px);
  }
`;

export default function Footer(props) {
  const { status } = props;
  return (
    <Wrapper>
    Developed by Harry Oosterveen,
      {' '}
      <a href="https://www.harryonline.net/" target="_blank" rel="noopener noreferrer">
HarryOnline
        <img alt="HarryOnline logo" src="https://s3-eu-west-1.amazonaws.com/logos.harryonline/ho_logo_24x26.png" />
      </a>
      {status}
    </Wrapper>
  );
}

Footer.propTypes = {
  status: PropTypes.string,
};
