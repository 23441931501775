import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { Alert, Button } from 'react-bootstrap';
import { ImCog, ImCross } from 'react-icons/im';
import { useHash, useToggle } from 'react-use';
import { Header } from '../PageLayout';
import useDatabase from './useDatabase';
import CustomList from './CustomList';
import EditCustom from './EditCustom';
import useLockTimer from '../useLockTimer';
import ProgressTimer from '../ProgressTimer';
import TimeDisplay from '../TimeDisplay';

function CustomWorkouts() {
  const dbProps = useDatabase();
  const [hash] = useHash();
  const {
    loading, value, deleteRecord, getByID,
  } = dbProps;
  const step = hash && getByID(Number(hash.slice(1)));

  const [editId, setEditId] = useState(0);
  const [editable, toggleEditable] = useToggle(false);

  const { getTime, setPause } = useLockTimer(1, step);

  if (loading) {
    return <p>Loading...</p>;
  }
  const hasItems = value && value.length > 0;
  const showAdd = !hasItems || editable;

  function handleDelete(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Workout verwijderen?')) {
      deleteRecord(id);
    }
  }

  function handleHide(done) {
    setEditId(0);
    if (done) {
      toggleEditable();
    }
  }

  return (
    <>
      <Header>
        <span>Custom Workouts</span>
      </Header>
      <main className="flex-grow-1 stretched-column justify-content-start">
        {hasItems ? (
          <>
            {step ? (
              <div className="flex-grow-1">
                <TimeDisplay className="float-right" getTime={getTime} />
                <h1>{step.name}</h1>
                <ProgressTimer step={step} next="" onPause={setPause} />
              </div>
            ) : (
              <div>
                <Button variant="link" size="sm" className="p-0 float-right text-muted" active={editable} onClick={toggleEditable} aria-label="Edit">
                  { editable ? <ImCross /> : <ImCog />}
                </Button>
                <p>
                  {editable ? 'Bewerk je workouts.' : 'Klik op een workout om deze te starten.'}
                </p>
              </div>
            )}
            <CustomList workouts={sortBy(value, 'name')} onEdit={setEditId} onDelete={handleDelete} editable={editable} />
          </>
        ) : (
          <Alert variant="info">Je hebt nog geen workouts.</Alert>
        )}
        {showAdd && <div><Button onClick={() => setEditId(-1)}>Workout toevoegen</Button></div>}
        <EditCustom id={editId} onHide={handleHide} />
      </main>
    </>
  );
}

export default CustomWorkouts;
