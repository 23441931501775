/**
 * Controller
 * Show time bar and start/next buttons
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ProgressTimer from './ProgressTimer';

function Controller(props) {
  const {
    step, next, autoStart, onPause,
  } = props;

  const { countDown, duration } = step;
  const [started, start] = useState(autoStart);
  useEffect(() => start(autoStart), [step, autoStart]);
  if (!countDown && !duration) {
    return <div className="text-right"><Link to={next} className="btn btn-success btn-lg">Done</Link></div>;
  }
  if (!started) {
    return (
      <div className="d-flex align-items-baseline">
        <Button size="lg" className="mr-2" onClick={() => start(true)}>Start</Button>
        {`and get ready in ${countDown} seconds`}
      </div>
    );
  }
  return <ProgressTimer step={step} next={next} onPause={onPause} />;
}

Controller.propTypes = {
  step: PropTypes.object.isRequired,
  next: PropTypes.string,
  autoStart: PropTypes.bool,
  onPause: PropTypes.func,
};

export default Controller;
