import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo192.png';

function HomeLink(props) {
  const { className } = props;
  return (
    <Link to="/">
      <img src={logo} alt="Home" height="24px" className={className} />
    </Link>
  );
}

HomeLink.propTypes = {
  className: PropTypes.string,
};

export default HomeLink;
