import decathlonEn from './decathlon-en';
import decathlonNl from './decathlon-nl';
import dailyDozen from './dailydozen';
import rugoefeningen from './rugoefeningen';
import hiit from './hiit';

export function getResources(language) {
  const resources = {
    dd: dailyDozen,
    hi: hiit,
    rug: rugoefeningen,
    str: language === 'en' ? decathlonEn : decathlonNl,
  };

  const list = Object.keys(resources).map((key) => ({
    key,
    name: resources[key].name,
    intro: resources[key].text.split('\n')[0].replace(/^#+\s+/, ''),
  }));

  return { ...resources, list };
}
