/**
 * Sounds from https://notificationsounds.com/featured-sounds
 */

import UIFx from 'uifx';
import mp3Tick from '../../assets/light.mp3';
import mp3Done from '../../assets/good-things-happen.mp3';
import mp3Start from '../../assets/definite.mp3';
import mp3Stop from '../../assets/to-the-point.mp3';
import mp3Halfway from '../../assets/quiet-knock.mp3';

export const tick = new UIFx(mp3Tick);
export const start = new UIFx(mp3Start);
export const done = new UIFx(mp3Done);
export const stop = new UIFx(mp3Stop);
export const halfway = new UIFx(mp3Halfway);
