import * as sounds from './sounds';

function playSound(time, currentStage, nextStage) {
  if (!currentStage) {
    return;
  }
  if (time >= 10 && time === Math.floor(currentStage.duration / 2)) {
    sounds.halfway.play();
    return;
  }
  if (time > 5 || time < 0) {
    return;
  }
  if (time > 0) {
    sounds.tick.play();
    return;
  }
  if (!nextStage) {
    sounds.done.play();
    return;
  }
  if (nextStage.variant === 'success') {
    sounds.start.play();
    return;
  }
  sounds.stop.play();
}

export default playSound;
