function makeSchema(list) {
  return list.map((name) => ({ name }));
}

const dbConfig = {
  name: 'workouts',
  version: 1,
  objectStoresMeta: [
    {
      store: 'custom',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: makeSchema(['name', 'duration', 'rest', 'repetitions']),
    },
  ],
};

export default dbConfig;
