/**
 * HomePage
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Markdown } from 'react-showdown';
import { Header } from './PageLayout';
import useResources from './useResources';

function HomePage() {
  const resources = useResources();
  return (
    <>
      <Header>
        <span>Harry&apos;s Workouts</span>
      </Header>
      <main className="overflow-auto">
        {resources.list.map((res) => (
          <section key={res.key}>
            <h2><Link to={`/${res.key}`}>{res.name}</Link></h2>
            <small><Markdown markup={res.intro} /></small>
          </section>
        ))}
        <h2><Link to="/custom">Custom Workouts</Link></h2>
        <small>Maak je eigen workouts.</small>
      </main>
    </>
  );
}

export default HomePage;
