import createPersistedState from 'use-persisted-state';
const useLanguageState = createPersistedState('language');

function selectLanguage(lang) {
  return lang && lang.startsWith('nl') ? 'nl' : 'en';
}

function useLanguage() {
  const [language, setLanguage] = useLanguageState(selectLanguage(navigator.language));
  return [selectLanguage(language), setLanguage];
}

export default useLanguage;
