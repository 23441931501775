import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap';
import { ImBin2, ImPencil2 } from 'react-icons/im';
import { useHash } from 'react-use';

function CustomList(props) {
  const {
    workouts, onEdit, onDelete, editable,
  } = props;

  const listRef = useRef();

  const [hash] = useHash();

  useEffect(() => {
    Array.from(listRef.current.children).forEach((elem) => {
      if (elem.dataset.rbEventKey === hash) {
        elem.classList.add('active');
      } else {
        elem.classList.remove('active');
      }
    });
  }, [hash]);

  return (
    <ListGroup className="mb-3" ref={listRef}>
      {workouts.map(({
        id, name, repetitions, duration, rest,
      }) => (
        <ListGroup.Item key={id} action={!editable} href={editable ? undefined : `#${id}`} className="d-flex reset-active">
          <span className="flex-grow-1">
            <b>{name}</b>
            {editable && (
              <span className="ml-3">
                <Button variant="link" size="sm" className="py-0" onClick={() => onEdit(id)} aria-label="Edit"><ImPencil2 /></Button>
                <Button variant="link" size="sm" className="py-0" onClick={() => onDelete(id)} aria-label="Delete"><ImBin2 className="text-danger" /></Button>
              </span>
            )}
          </span>
          {` ${repetitions}x ${duration}s, ${rest}s rust`}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}
CustomList.propTypes = {
  workouts: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
};

export default CustomList;
