import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useInterval } from 'react-use';

function TimeDisplay(props) {
  const { getTime, className } = props;
  const [time, setTime] = useState(0);
  useInterval(() => setTime(getTime()), 200);
  const timeString = new Date(time).toISOString().slice(11, 19).replace(/^0+:?/, '');
  const allClasses = classNames('text-monospace fs-6', className);
  return <span className={allClasses}>{timeString}</span>;
}

TimeDisplay.propTypes = {
  getTime: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default TimeDisplay;
