/**
 * App
 */

import React from 'react';
// import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IconContext } from 'react-icons/lib';
import 'bootstrap/dist/css/bootstrap.css';
import { Page, Footer } from './components/PageLayout';
import GlobalStyle from './styles/GlobalStyles';
import HomePage from './components/HomePage';
import Exercise from './components/Exercise';
import DatabaseProvider from './components/CustomWorkouts/DatabaseProvider';
import useResources from './components/useResources';
import CustomWorkouts from './components/CustomWorkouts';

function App() {
  const { list } = useResources();
  const exercisePath = `/(${list.map((o) => o.key).join('|')})`;
  return (
    <Router>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <DatabaseProvider store="custom">
          <Page className="stretched-column">
            <GlobalStyle />
            <div className="stretched-column">
              <Switch>
                <Route path={exercisePath}><Exercise /></Route>
                <Route path="/custom"><CustomWorkouts /></Route>
                <Route default><HomePage /></Route>
              </Switch>
            </div>
            <Footer />
            <Button type="button" className="close" />
          </Page>
        </DatabaseProvider>
      </IconContext.Provider>
    </Router>
  );
}

export default App;
