/**
 * Images
 * Show one or more images
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  img {
    max-width: ${(p) => p.width}%;
    max-height: calc(60vw);
  }
  @media (orientation: landscape) {
    img {
      max-height: calc(60vh);
    }
  }
  margin-bottom: 1rem;
  text-align: center;
  justify-content: space-between;
 `;

function Images(props) {
  const { src, alt } = props;
  const list = Array.isArray(src) ? src : [src];
  const width = Math.floor(100 / list.length);
  return (
    <Wrapper width={width}>
      {list.map((img) => <img key={img} src={img} alt={alt} />)}
    </Wrapper>
  );
}

Images.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  alt: PropTypes.string.isRequired,
};

export default Images;
