/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';
import { initDB, useIndexedDB } from 'react-indexed-db';
import { useAsyncFn } from 'react-use';
import { find } from 'lodash';
import dbConfig from './dbConfig';
initDB(dbConfig);

export const DatabaseContext = createContext();

function DatabaseProvider(props) {
  const { store, children } = props;
  const {
    getAll, add, update, deleteRecord,
  } = useIndexedDB(store);
  const [state, doFetch] = useAsyncFn(getAll);
  useEffect(() => {
    doFetch();
  }, [doFetch]);
  const { value } = state;
  const withFetch = (fn) => (prop) => fn(prop).then(doFetch);

  const dbProps = {
    ...state,
    getByID: useCallback((id) => find(value, { id }), [value]),
    add: useCallback(withFetch(add), [value]),
    update: useCallback(withFetch(update), [value]),
    deleteRecord: useCallback(withFetch(deleteRecord), [value]),
  };

  return (
    <DatabaseContext.Provider value={dbProps}>
      {children}
    </DatabaseContext.Provider>
  );
}

DatabaseProvider.propTypes = {
  store: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default DatabaseProvider;
