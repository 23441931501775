/**
 * useLockTimer
 */

import { useEffect, useState } from 'react';
import useTimer from './useTimer';
import useWakeLock from './useWakeLock';
import { TIMER_START, TIMER_RESET, TIMER_STOP } from './useTimer/constants';

function useLockTimer(idx, step) {
  const [pause, setPause] = useState(false);
  function getAction() {
    if (idx === 0) {
      return TIMER_RESET;
    }
    return step && !pause ? TIMER_START : TIMER_STOP;
  }
  const action = getAction();
  useEffect(() => setPause(false), [idx, step]);

  const { getTime, dispatch } = useTimer();
  const setLocked = useWakeLock()[1];
  useEffect(() => {
    dispatch(action);
    setLocked(action === TIMER_START);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return { getTime, setPause };
}
// time: new Date(time).toISOString().slice(11, 19).replace(/^0+:?/, ''),

export default useLockTimer;
