import theDailyDozen from '../assets/dailyDozenImages/the-daily-dozen.jpg';
import steamEngine from '../assets/dailyDozenImages/steamEngine.jpg';
import toeToucher from '../assets/dailyDozenImages/toeToucher.jpg';
import twister from '../assets/dailyDozenImages/twister.jpg';
import halfMoon from '../assets/dailyDozenImages/halfMoon.jpg';
import squat from '../assets/dailyDozenImages/squat.jpg';
import lunges from '../assets/dailyDozenImages/lunges.jpg';
import plie from '../assets/dailyDozenImages/plie.jpg';
import pushUps from '../assets/dailyDozenImages/pushUps.jpg';
import crocodiles from '../assets/dailyDozenImages/crocodiles.jpg';
import scissors from '../assets/dailyDozenImages/scissors.jpg';
import steamOnBack from '../assets/dailyDozenImages/steamOnBack.jpg';
import plank from '../assets/dailyDozenImages/plank.jpg';

/* eslint-disable no-irregular-whitespace */
const dailyDozen = {
  author: 'adventX',
  name: 'Daily Dozen',
  source: '/download/the-daily-dozen.pdf',
  img: theDailyDozen,
  text: `**12** exercises in **12** minutes: Do each exercise for 45 seconds, then rest for 15 seconds.
  
  - [Sheet](/download/the-daily-dozen.pdf)
  - [Folder](/download/Daily_Dozen_Fit_By_Nature.pdf)`,
  autoStart: true,
  defaultStep: {
    countDown: 15,
    repetitions: 1,
    duration: 45,
    rest: 15,
  },
  steps: [
    {
      name: '1. Steam Engine',
      img: steamEngine,
      text: 'Active Stretch: Warms up front and side core muscles, upper back, and hip flexors.',
    },
    {
      name: '2. Toe Toucher',
      img: toeToucher,
      text: 'Active Stretch: Warms up the core and hamstrings.',
    },
    {
      name: '3. Twister',
      img: twister,
      text: 'Works the oblique (side abdominal) muscles and the core.',
    },
    {
      name: '4. Half Moon',
      img: halfMoon,
      text: 'Works the abdominal and side muscle groups.',
    },
    {
      name: '5. 3/4 Squat',
      img: squat,
      text: 'Strengthens the quadriceps (front upper leg muscles).',
    },
    {
      name: '6. Lunges',
      img: lunges,
      text: 'Strengthens and increases flexibility in quadriceps and hamstrings.',
    },
    {
      name: '7. Plié / Sumo Squat',
      img: plie,
      text: 'Makes your butt tight.',
    },
    {
      name: '8. Push Ups',
      img: pushUps,
      text: 'Strengthens core, abs, pectorals (chest), shoulders, and arms.',
    },
    {
      name: '9. Crocodiles',
      img: crocodiles,
      text: 'Strengthens and flattens lower abdominals, strengthens lower back.',
    },
    {
      name: '10. Scissors',
      img: scissors,
      text: 'Strengthens abs, lower back, and thighs.',
    },
    {
      name: '11. Steam Engines on Back',
      img: steamOnBack,
      text: 'Strengthens full range of abdominals.',
    },
    {
      name: '12. Plank',
      img: plank,
      text: 'The ultimate tummy flattener.',
    },
  ],
};

export default dailyDozen;
