/**
 * Header
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { red500 } from '../../styles/colors';
import HomeLink from './HomeLink';

const Wrapper = styled.header`
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  a.source {
    font-size: smaller;
  }
  nav {
    flex-grow: 1;
    text-align: right;
  }
  border-bottom: 1px solid ${red500};
  button.dropdown-toggle {
    height: 24px;
    padding: 0 0.5rem;
  }
`;

function Header(props) {
  const { children, className } = props;
  return (
    <Wrapper className={className}>
      <HomeLink className="mr-2" />
      {children}
    </Wrapper>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Header;
