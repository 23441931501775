import produce from 'immer';
import {
  TIMER_RESET, TIMER_START, TIMER_STOP,
} from './constants';

const reducer = produce((draft, action) => {
  const now = Date.now();
  switch (action) {
    case TIMER_RESET:
      draft.startTime = 0;
      draft.currentTime = 0;
      break;
    case TIMER_START:
      if (draft.startTime <= 0) {
        draft.startTime += now;
        draft.currentTime = now - draft.startTime;
      }
      break;
    case TIMER_STOP:
      if (draft.startTime > 0) {
        draft.currentTime = now - draft.startTime;
        draft.startTime -= now;
      }
      break;
    default:
  }
});

export default reducer;
