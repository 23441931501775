function expandSteps(steps, defaultStep = {}) {
  const result = [];
  let idx = 0;
  // let withDuration = true;
  function addStep(step, nav) {
    const fullStep = {
      id: ++idx, nav, ...defaultStep, ...step,
    };
    if (!fullStep.duration) {
      fullStep.countDown = 0;
    }
    result.push(fullStep);
    // withDuration = Boolean(fullStep.duration);
  }
  steps.forEach(({
    repeat, defaultStep: subDefault, steps: subSteps, ...step
  }) => {
    addStep(step, true);
    if (repeat) {
      for (let i = 0; i < repeat; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const sub of subSteps) {
          addStep({ ...subDefault, ...sub }, false);
        }
      }
    }
  });
  return result;
}

export default expandSteps;
